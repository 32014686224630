import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'animate.css';
import CountUp from 'react-countup';

const Retrospective = () => {
  const [forkChange, setForkChange] = useState(0);
  const [brandChange, setBrandChange] = useState(0);
  const [avalancheannouced, setAvalancheAnnoucedChange] = useState(0);
  const [stakingannouced, setStakingAnnouced] = useState(0);
  const [value, setValue] = useState('fork');
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setValue(value);
    setIsOpen(false);
  };

  const calcForkdayChange = (xecprice, bchprice) => {
    const forkdayprice = 0.0000104172;
    const forkdaypriceBCH = 200;
    let xecchange = ((xecprice - forkdayprice) / forkdayprice) * 100;
    let bchchange = ((bchprice - forkdaypriceBCH) / forkdaypriceBCH) * 100;
    let diff = xecchange - bchchange;
    setForkChange(diff);
  };

  const calcBranddayChange = (xecprice, bchprice) => {
    const branddayprice = 0.0000311694;
    const branddaypriceBCH = 500.43;
    let xecchange = ((xecprice - branddayprice) / branddayprice) * 100;
    let bchchange = ((bchprice - branddaypriceBCH) / branddaypriceBCH) * 100;
    let diff = xecchange - bchchange;
    setBrandChange(diff);
  };

  const calcAvalancheAnnoucedChange = (xecprice, bchprice) => {
    const branddayprice = 0.00003793513996;
    const branddaypriceBCH = 115.57808922877;
    let xecchange = ((xecprice - branddayprice) / branddayprice) * 100;
    let bchchange = ((bchprice - branddaypriceBCH) / branddaypriceBCH) * 100;
    let diff = xecchange - bchchange;
    setAvalancheAnnoucedChange(diff);
  };

  const calcStakingAnnoucedChange = (xecprice, bchprice) => {
    const branddayprice = 0.00002344;
    const branddaypriceBCH = 194.6;
    let xecchange = ((xecprice - branddayprice) / branddayprice) * 100;
    let bchchange = ((bchprice - branddaypriceBCH) / branddaypriceBCH) * 100;
    let diff = xecchange - bchchange;
    setStakingAnnouced(diff);
  };

  const GetPrices = () => {
    const priceApi =
      'https://api.coingecko.com/api/v3/simple/price?ids=ecash%2Cbitcoin-cash&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true';
    axios
      .get(priceApi)
      .then((response) => {
        calcForkdayChange(
          response.data.ecash.usd,
          response.data['bitcoin-cash'].usd
        );
        calcBranddayChange(
          response.data.ecash.usd,
          response.data['bitcoin-cash'].usd
        );
        calcAvalancheAnnoucedChange(
          response.data.ecash.usd,
          response.data['bitcoin-cash'].usd
        );
        calcStakingAnnoucedChange(
          response.data.ecash.usd,
          response.data['bitcoin-cash'].usd
        );
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  useEffect(() => {
    GetPrices();
  }, []);

  return (
    <div className="retrospective-ctn animate__animated animate__fadeInUp animate-delay-2">
      <h3>Returns</h3>
      <div className="what-if-box">
        <h3>If you swapped your BCH for XEC on</h3>
        <div className="select2" onClick={toggling}>
          {value === 'fork'
            ? 'November 15, 2020 (fork day)'
            : value === 'brand'
            ? 'July 1, 2021 (rebrand day)'
            : value === 'avalanche'
            ? 'August 28, 2022 (avalanche launch announcement)'
            : 'September 7, 2023 (staking rewards code release)'}
          {isOpen && (
            <div className="options-ctn">
              <div
                onClick={onOptionClicked('fork')}
                style={value === 'fork' ? { backgroundColor: '#0074c2' } : null}
              >
                November 15, 2020 (fork day)
              </div>
              <div
                onClick={onOptionClicked('brand')}
                style={
                  value === 'brand' ? { backgroundColor: '#0074c2' } : null
                }
              >
                July 1, 2021 (rebrand day)
              </div>
              <div
                onClick={onOptionClicked('avalanche')}
                style={
                  value === 'avalanche' ? { backgroundColor: '#0074c2' } : null
                }
              >
                August 28, 2022 (avalanche launch announcement)
              </div>
              <div
                onClick={onOptionClicked('staking')}
                style={
                  value === 'staking' ? { backgroundColor: '#0074c2' } : null
                }
              >
                September 7, 2023 (staking rewards code release)
              </div>
            </div>
          )}
        </div>
        <h3>today you would be up</h3>

        {value === 'fork' && (
          <div
            className="up-value"
            style={forkChange < 0 ? { color: 'rgb(239, 68, 68)' } : null}
          >
            <CountUp
              start={0}
              end={forkChange}
              duration={0.8}
              decimals={2}
              decimal="."
              separator=","
              prefix={forkChange < 0 ? null : '+'}
              suffix="%"
            />
          </div>
        )}
        {value === 'brand' && (
          <div
            className="up-value"
            style={brandChange < 0 ? { color: 'rgb(239, 68, 68)' } : null}
          >
            <CountUp
              start={0}
              end={brandChange}
              duration={0.8}
              decimals={2}
              decimal="."
              separator=","
              prefix={brandChange < 0 ? null : '+'}
              suffix="%"
            />
          </div>
        )}
        {value === 'avalanche' && (
          <div
            className="up-value"
            style={avalancheannouced < 0 ? { color: 'rgb(239, 68, 68)' } : null}
          >
            <CountUp
              start={0}
              end={avalancheannouced}
              duration={0.8}
              decimals={2}
              decimal="."
              separator=","
              prefix={avalancheannouced < 0 ? '' : '+'}
              suffix="%"
            />
          </div>
        )}
        {value === 'staking' && (
          <div
            className="up-value"
            style={stakingannouced < 0 ? { color: 'rgb(239, 68, 68)' } : null}
          >
            <CountUp
              start={0}
              end={stakingannouced}
              duration={0.8}
              decimals={2}
              decimal="."
              separator=","
              prefix={stakingannouced < 0 ? '' : '+'}
              suffix="%"
            />
          </div>
        )}
      </div>
      <a
        href="https://www.buyecash.com"
        target="_blank"
        rel="noreferrer"
        className="learn-btn"
      >
        <div>Buy eCash</div>
      </a>
    </div>
  );
};

export default Retrospective;
