import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'animate.css';
import XEC from './images/ecash-logo.png';
import BCH from './images/bitcoin-cash-logo.png';

function formatNumber(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Chart = ({ xec, bch, gains, blockchair, ecashtransactions }) => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [abc_commits, setABCCommits] = useState(0);
  const [bchn_commits, setBCHNCommits] = useState(0);
  const getABCCommits = () => {
    const url =
      'https://api.github.com/repos/Bitcoin-ABC/bitcoin-abc/commits?since=2020-11-15T00:03:34+0000';
    axios.get(url).then((response) => {
      if (response.headers) {
        let number_of_pages =
          parseInt(response.headers.link.split('&page=')[2].split('>;')[0]) *
          30;

        setABCCommits(number_of_pages);
      }
    });
  };

  const getBCHNCommits = () => {
    const url =
      'https://api.github.com/repos/bitcoin-cash-node/bitcoin-cash-node/commits?since=2020-11-15T00:03:34+0000';
    axios.get(url).then((response) => {
      if (response.headers) {
        let number_of_pages =
          parseInt(response.headers.link.split('&page=')[2].split('>;')[0]) *
          30;

        setBCHNCommits(number_of_pages);
      }
    });
  };

  // const getBCHNCommits = (url) => {
  //   axios.get(url).then((response) => {
  //     if (response.headers) {
  //       let next_page = response.headers['x-next-page'];
  //       let this_page = response.headers['x-page'];
  //       let totalCommits = 0;
  //       // get number of commits from new pages after 39
  //       let NewPages = (this_page - 8) * 100;
  //       if (next_page !== '') {
  //         getBCHNCommits(
  //           'https://gitlab.com/api/v4/projects/16979301/repository/commits?since=2020-11-15T00:03:34+0000&page=' +
  //             next_page
  //         );
  //       } else {
  //         // 760 is the number of commits as of 10-04-21 minus 16 which is the amount that was on the last page
  //         totalCommits = response.data.length + 700 + NewPages;
  //       }

  //       setBCHNCommits(totalCommits);
  //     }
  //   });
  // };

  useEffect(() => {
    getABCCommits();
    getBCHNCommits();
  }, []);

  return (
    <div className="chart-ctn animate__animated animate__fadeInUp animate-delay-2">
      <table
        style={{ width: '100%' }}
        border="1"
        cellPadding="20"
        className="comparison-table"
      >
        <tbody>
          <tr className="topRow">
            <td>&nbsp;</td>
            <td className="ecash-cell-border">
              <img src={XEC} alt="ecash" className="row-logo" />
            </td>
            <td>
              <img src={BCH} alt="ecash" className="row-logo" />
            </td>
          </tr>
          <tr className="color-cell">
            <td>Market Cap</td>
            <td className="ecash-cell">${formatNumber(xec[2])}</td>
            <td>${formatNumber(bch[2])}</td>
          </tr>
          <tr className="color-cell">
            <td>Current Price</td>
            <td className="ecash-cell">${xec[0].toFixed(7)}</td>
            <td>${formatNumber(bch[0].toFixed(2))}</td>
          </tr>
          <tr className="color-cell">
            <td>Transactions (24hr)</td>
            <td className="ecash-cell">{formatNumber(ecashtransactions)}</td>
            <td>{formatNumber(blockchair[3])}</td>
          </tr>
          <tr className="color-cell">
            <td>Market Dominance</td>
            <td className="ecash-cell">{blockchair[0]}%</td>
            <td>{blockchair[2]}%</td>
          </tr>
          <tr className="color-cell">
            <td
              className="question"
              onMouseEnter={() => setShow2(true)}
              onMouseLeave={() => setShow2(false)}
            >
              Codebase Commits <span>?</span>
              {show2 ? (
                <div className="tool-tip" style={{ top: '-67px' }}>
                  Number of commits to each node software codebase since Nov 15,
                  2020
                </div>
              ) : null}
            </td>
            <td className="ecash-cell">{formatNumber(abc_commits)}</td>
            <td>{formatNumber(bchn_commits)}</td>
          </tr>
          <tr className="color-cell">
            <td
              className="question"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              Monthly Return <span>?</span>
              {show ? (
                <div className="tool-tip" style={{ top: '-130px' }}>
                  Average amount of asset appreciation per month since
                  respective forks
                  <br />
                  <br />
                  Nov 15, 2020, 1 XEC = $0.0000104172
                  <br />
                  Aug 1, 2017, 1 BCH = $363.58
                </div>
              ) : null}
            </td>
            <td className="ecash-cell">{gains[0]}%</td>
            <td>{gains[1]}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Chart;
