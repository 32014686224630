import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';

const HistoricalChart = ({ xecChartData, bchChartData, loading }) => {
  const chartContainerRef = useRef();
  // const chart = useRef();
  // const resizeObserver = useRef();
  const format = (x) => {
    let x2 = Number(x).toFixed(2);
    return x2;
  };

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: {
        backgroundColor: '#1c2539',
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: 'rgba(255, 255, 255, 0.1)',
          visible: false,
        },
        horzLines: {
          color: 'rgba(255, 255, 255, 0.05)',
        },
      },
      crosshair: {
        mode: CrosshairMode.Magnet,
      },
      priceScale: {
        borderColor: '#485c7b',
      },
      timeScale: {
        borderColor: '#485c7b',
        leftOffset: 0,
      },
      localization: {
        priceFormatter: (x) => '$' + format(x) + 'B',
      },
    });
    prepareChart(chart);

    return () => {
      chart.remove();
    };
  }, [xecChartData, bchChartData]);

  const prepareChart = (chart) => {
    const BCHSeries = chart.addAreaSeries({
      topColor: 'rgba(10, 193, 142,0.56)',
      bottomColor: 'rgba(10, 193, 142,0.04)',
      lineColor: '#0ac18e',
      lineWidth: 2,
      priceLineVisible: false,
    });

    const XECSeries = chart.addAreaSeries({
      topColor: 'rgba(0, 171, 231,0.56)',
      bottomColor: 'rgba(0, 171, 231,0.04)',
      lineColor: '#00abe7',
      lineWidth: 2,
      priceLineVisible: false,
    });

    BCHSeries.setData(bchChartData);
    XECSeries.setData(xecChartData);
    chart.timeScale().fitContent();
  };

  // Resize chart on container resizes.
  // useEffect(() => {
  //   resizeObserver.current = new ResizeObserver((entries) => {
  //     const { width, height } = entries[0].contentRect;
  //     chart.current.applyOptions({ width, height });
  //     setTimeout(() => {
  //       chart.current.timeScale().fitContent();
  //     }, 0);
  //   });

  //   resizeObserver.current.observe(chartContainerRef.current);

  //   return () => resizeObserver.current.disconnect();
  // }, []);

  return (
    <div
      className="graph-ctn animate__animated animate__fadeInUp"
      style={{ animationDelay: '1s' }}
    >
      <h3>Historical Market Cap</h3>
      <div style={loading === false ? { display: 'none' } : null}>
        Loading...
      </div>
      <div
        // style={loading === true ? { display: 'none' } : null}
        ref={chartContainerRef}
        className="inner_graph-ctn"
      />
    </div>
  );
};

export default HistoricalChart;
